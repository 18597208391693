<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
    <ayl-main title="车辆信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="content" v-for="(item, idx) in basicTitle" :key="idx">
          <span>{{ item.lable }}：</span>
          <span>{{ basicTitleData[item.value] || '- -' }}</span>
        </div>
      </div>
    </ayl-main>
    <ayl-main title="报警信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="content" v-for="(item, idx) in otherTitle" :key="idx" v-show="idx < (otherTitle.length-1)">
            <span>{{ item.lable }}：</span>
            <span>{{ otherTitleData[item.value] || '- -' }}</span>
        </div>
        <div class="content content-map">
            <span>位置：</span>
            <span v-text="otherTitleData.postion"></span>
            <a class="mapView" @click="centerDialogVisible = true">查看地图</a>
            <el-dialog
            :visible.sync="centerDialogVisible"
            width="50%"
            @open="newInitAmap"
            center>
            <span>
              <div id="container" style="width: 100%; height: 600px"></div>
            </span>
          </el-dialog>
        </div>
        <div class="content">
            <span>附件数：</span>
            <span v-text="otherTitleData.numberOfAttachments" v-if="otherTitleData.attache ===1"></span>
            <span v-else>0</span>
            <a class="mapView" @click="annex()" v-if="otherTitleData.attache === 1">查看附件</a>
        </div>
        <!-- 查看附件 -->
        <el-dialog title="查看附件" :visible.sync="dialogFormVisible">
          <div class="attachment-main">
            <div class="left-img">
              <div style="width: 100%;height: 100%;" v-for="(item,index) in videoUrl" :key="index">
                <video :src="item" type="video/mp4" controls="controls" preload="auto" style="width: 100%" v-if="videoIdx === 1 && videoType === 'mp4'"></video>
                <ayl-ts :url='item' style="width: 100%;height: 100%;" v-if="videoIdx === 1 && videoType === 'ts'"></ayl-ts>
                <img :src="url==='' ? playerOptions.images[0] : url" class="images" v-image-preview v-else-if="videoIdx !== 1">
              </div> 
              <!-- <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" style="width: 100%" v-if="videoIdx === 1"></video>
              <img :src="url==='' ? playerOptions.images[0] : url" class="images" v-image-preview v-else> -->
            </div>
            <div class="right-img">
              <div style="margin-bottom: 5px;" v-for="(item,index) in videoUrl" :key="index">
                <video :src="item" type="video/mp4" controls="controls" preload="auto" class="images" @click="videoItem(1)" v-if="videoType === 'mp4'"></video>
                <div style="width:100%;height: 100%" @click.stop="videoItem(1)">
                  <ayl-ts :url='item' style="width: 100%;height: 100%;" v-if="videoType === 'ts'" class="images"></ayl-ts>
                </div> 
              </div>
              <!-- <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" class="images" @click="videoItem(1)"></video> -->
              <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx,0)" :src="item" class="images">
            </div>
          </div>
      </el-dialog>
      </div>
    </ayl-main>
    <ayl-main title="处理信息" :isCollapse="false">
      <div slot="main" class="p24px" v-if="form.desc === '未处理'">
        <div class="content" style="width: 200px">
            <span>处理情况：</span>
            <span v-text="form.desc" :style="{'color': (form.desc === '未处理' ? '#F5222D':'#666666')}"></span>
        </div>
        <el-button v-if="form.desc === '未处理'" type="primary" @click= onDialog()>处理</el-button>
        <el-dialog
        title="报警处理"
        :visible.sync="processDialog"
        width="40%"
        center>
        <span>
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                <el-form-item label="处理方式"  prop="type">
                    <el-radio-group v-model="form.type" :max="1">
                      <el-radio label="语音播报" name="type"></el-radio>
                      <el-radio label="实时对讲" name="type"></el-radio>
                      <el-radio label="其他" name="type"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="处理内容">
                    <el-input maxlength="20" type="textarea" placeholder="请输入20字以内的播报内容,不填写则不播报" :autosize="{ minRows: 4, maxRows: 12 }" v-model="form.dealContent"></el-input>
                </el-form-item>
            </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="processDialog = false">取 消</el-button>
            <el-button type="primary" @click="submitForms">确 定</el-button>
        </span>
        </el-dialog>
      </div>
      <div slot="main" class="p24px" v-else>
        <div class="content" v-for="(item, idx) in information" :key="idx" v-show="idx < (information.length-1)">
            <span>{{ item.lable }}：</span>
            <span>{{ form[item.value] || '- -' }}</span>
        </div>
        <div class="content">
            <span>处理内容：</span>
            <span v-text="form.dealContent"></span>
            <!-- <a class="mapView" @click="$router.push({path:'/alarm/annex', query: { attacheKey: otherTitleData.attacheKey, tenantId: otherTitleData.tenantId,}})" v-if="otherTitleData.attach === 1">查看附件</a> -->
        </div>
      </div>
    </ayl-main>
    <div class="tac" style="margin-top: 40px">
      <el-button style="margin-left: 30px" @click="$router.go(-1)">返回</el-button>
    </div>
    </div>

  </div>
</template>

<script>
  export default {
    data () {
      return {
        centerDialogVisible: false,
        processDialog: false,
        dialogFormVisible: false,
        formLabelWidth: '120px',
        map: null,
        nav: [
          {name: "安全中心", path: ""},
          {name: "报警管理", path: "/safety-center/alarm-management"},
          {name: "报警中心", path: "/safety-center/alarm-management"},
          {name: "查看详情", path: ""},
        ],
        basicTitle:[
          {
            lable:'车牌号码',
            value:'name'
          },
          {
            lable:'设备号',
            value:'device'
          },
          {
            lable:'SIM卡号',
            value:'card'
          },
          {
            lable:'公司',
            value:'own'
          },
          {
            lable:'驾驶员',
            value:'driverName'
          },
          {
            lable:'身份证号',
            value:'identity'
          },
          {
            lable:'手机号码',
            value:'information'
          },
        ],
        basicTitleData: {
          name: null,
          device: null,
          card: null,
          own: null,
          driverName: null,
          identity: null,
          information: null,
        },
        otherTitle: [
          {
            lable:'状态',
            value:'status'
          },
          {
            lable:'行驶速度',
            value:'drivingSpeed'
          },
          {
            lable:'行驶里程',
            value:'drivenDistance'
          },
          {
            lable:'经度',
            value:'longitude'
          },
          {
            lable:'纬度',
            value:'latitude'
          },
          {
            lable:'海拔',
            value:'altitude'
          },
          // {
          //   lable:'位置',
          //   value:'postion'
          // },
          {
            lable:'报警类型',
            value:'alarmType'
          },
          {
            lable:'报警等级',
            value:'alarmLevel'
          },
          {
            lable:'报警开始时间',
            value:'alarmStartTime'
          },
          {
            lable:'报警结束时间',
            value:'alarmEndTime'
          },
          {
            lable:'附件数（已接受数/总数）',
            value:'numberOfAttachments',
          },
        ],
        otherTitleData:{
          status: null,
          drivingSpeed: null,
          drivenDistance: null,
          longitude: null,
          latitude: null,
          altitude: null,
          postion: null,
          alarmType: null,
          alarmLevel: null,
          alarmStartTime: null,
          alarmEndTime: null,
          numberOfAttachments: null,
          attacheKey: null,
          tenantId: null,
        },

        information:[
          {
            lable:'处理状态',
            value:'desc'
          },
          {
            lable:'处理人',
            value:'dealName'
          },
          {
            lable:'处理时间',
            value:'dealTime'
          },
          {
            lable:'处理方式',
            value:'dealType'
          },
          {
            lable:'处理内容',
            value:'dealContent',
          }
        ],
        form: {
          type: [],
          desc: null,
          dealName: null,
          dealType: null,
          dealContent: null,
          dealTime: null,
          deviceId: null,
          alarmStartTime: null,
          attacheKey: null,
        },
        rules: {
          dealContent: [
            { required: true, message: '请输入处理内容', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '请至少选择一个处理方式', trigger: 'change' }
          ],
        },
        submitLoading: false,

        url: '',
        videoUrl: [],
        videoType: null,
        videoIdx: 1,
        playerOptions : {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [],
            images: [],
            poster: "", //你的封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true  //全屏按钮
            }
        },
      }
    },
    methods:{
      async annex(){
        this.dialogFormVisible = true
        let videoData = await this.$api.findFileByPrefix({
          tenantId: this.otherTitleData.tenantId,
          attacheKey: this.otherTitleData.attacheKey
        })
        this.playerOptions.images = videoData[0].images
        if(videoData[0].sources[0].type === 'video/mp4') {
          this.videoType = 'mp4'
          this.playerOptions.sources = videoData[0].sources
          this.videoUrl = [videoData[0].sources[0].src]
        } else {
          var _this = this
          _this.videoType = 'ts'
          _this.videoUrl = [videoData[0].sources[0].src];

        }
        // this.playerOptions.sources = videoData[0].sources
        // this.videoUrl = this.playerOptions.sources[0].src
        //this.$router.push({path:'/safety-center/alarm-management/annexs',query: { attacheKey: val.attacheKey }});
      },
      chg:function(ind,val){
        this.url =this.playerOptions.images[ind]
        this.videoIdx = val
      },
      videoItem(val){
        this.videoIdx = val
      },
      initAmap (){
        // 构造点标记
        var marker = new AMap.Marker({
          icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
          position: [this.otherTitleData.longitude,this.otherTitleData.latitude]
        });
        // 构造矢量圆形
        var circle = new AMap.Circle({
            center:[this.otherTitleData.longitude,this.otherTitleData.latitude], // 圆心位置
            radius: 30,  //半径
            strokeColor: "#b1d0f2",  //线颜色
            strokeOpacity: 1,  //线透明度
            strokeWeight: 1,  //线粗细度
            fillColor: "#d7e4f0",  //填充颜色
            fillOpacity: 0.8 //填充透明度
        });
        //初始化地图
        this.map= new AMap.Map('container', {
          center:[this.otherTitleData.longitude,this.otherTitleData.latitude],
          resizeEnable: true,
          zoom: 10,
        });
        this.map.add([marker,circle]);
      },
      newInitAmap() {
        this.$nextTick(() => {
            this.initAmap()
        });
      },


      onDialog() {
        return this.processDialog = true
      },
      async onSubmit(val) {
          await this.$api.updateAlarmDealInfo({
            deviceAlarmInfoVOIOV:{
              deviceId: this.form.deviceId,
              alarmStartTime: this.form.alarmStartTime,
              alarmIdentifier: this.otherTitleData.alarmType,
              alarmType: this.otherTitleData.alarmLevel,
              tenantId: this.form.tenantId,
              dealType: this.form.type,
              dealContent: this.form.dealContent,
              // dealStatus: '已处理',
              // dealName: '001',
              // dealTime: (new Date()).valueOf(),
            }
          })
          this.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success'
          })
          this.$router.go(-1)
          // await this.$router.push({path:'/safety-center/alarm-management'});
        },
      submitForms() {
      this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.onSubmit()
          } catch (e){}
          this.submitLoading = false
        })
      },
    },
    async mounted () {
      // await this.$search(this.table)
      let alarmData= await this.$api.getAlarmEvidence({
        alarmName: this.$route.query.alarmName,
        alarmType: this.$route.query.alarmType,
        deviceId: this.$route.query.deviceId,
        alarmStartTime: this.$route.query.alarmStartTime,
        tenantId: this.$route.query.tenantId
      })
      this.basicTitleData.name = alarmData.hasOwnProperty('plateNumber') ? alarmData.plateNumber : null
      this.basicTitleData.device = alarmData.hasOwnProperty('deviceId') ? alarmData.deviceId : null
      this.basicTitleData.card = alarmData.hasOwnProperty('simCardNumber') ? alarmData.simCardNumber : null
      this.basicTitleData.own = alarmData.hasOwnProperty('company') ? alarmData.company : null
      this.basicTitleData.driverName = alarmData.hasOwnProperty('driverName') ? alarmData.driverName : null
      this.basicTitleData.identity = alarmData.hasOwnProperty('driverIdCardNumber') ? alarmData.driverIdCardNumber : null
      this.basicTitleData.information = alarmData.hasOwnProperty('driverContactPhone') ? alarmData.driverContactPhone : null

      this.otherTitleData.status = alarmData.hasOwnProperty('deviceStatus') ? alarmData.deviceStatus : null
      this.otherTitleData.drivingSpeed = alarmData.hasOwnProperty('speed') ? alarmData.speed : null
      this.otherTitleData.drivenDistance = alarmData.hasOwnProperty('mileage') ? alarmData.mileage : null
      this.otherTitleData.longitude = alarmData.hasOwnProperty('position') ? alarmData.position.longitude : null
      this.otherTitleData.latitude = alarmData.hasOwnProperty('position') ? alarmData.position.latitude : null
      this.otherTitleData.altitude = alarmData.hasOwnProperty('altitude') ? alarmData.altitude : null
      this.otherTitleData.postion = alarmData.hasOwnProperty('location') ? alarmData.location : null
      this.otherTitleData.alarmType = alarmData.hasOwnProperty('alarmName') ? alarmData.alarmName : null
      this.otherTitleData.alarmLevel = alarmData.hasOwnProperty('alarmType') ? alarmData.alarmType : null
      this.otherTitleData.alarmStartTime = alarmData.hasOwnProperty('alarmStartTime') ? this.$dayjs(alarmData.alarmStartTime).format('YYYY-MM-DD HH:mm:ss') : null
      this.otherTitleData.alarmEndTime = alarmData.hasOwnProperty('alarmEndTime') ? this.$dayjs(alarmData.alarmEndTime).format('YYYY-MM-DD HH:mm:ss') : null
      this.otherTitleData.numberOfAttachments = alarmData.hasOwnProperty('attacheCount') ? alarmData.attacheCount : null
      this.otherTitleData.attache = alarmData.attache
      this.otherTitleData.attacheKey = alarmData.attacheKey
      this.otherTitleData.tenantId = alarmData.tenantId


      this.form.desc = alarmData.dealStatusDes
      this.form.dealName = alarmData.dealUserName
      this.form.dealTime = this.$dayjs(alarmData.dealTime).format('YYYY-MM-DD HH:mm:ss')
      this.form.dealType = alarmData.dealType
      this.form.dealContent = alarmData.dealContent
      this.form.tenantId = alarmData.tenantId
      this.form.deviceId = alarmData.deviceId   //设备Id
      this.form.alarmStartTime = alarmData.alarmStartTime
      this.form.attacheKey = alarmData.attacheKey
    }
  }
</script>
<style lang='sass' scoped>
.clearBox
  .content
    .mapView
      color: #0575E6
      margin-left: 14px
      cursor: pointer

  .content-map
    width: 600px
.content
    display: inline-block
    width: 320px
    margin-bottom: 36px

/deep/ .el-dialog__header
    padding-bottom: 30px
.content-box /deep/ .el-form-item
    width: 100%
.attachment-main
    width: 100%
    .right-img
      width: 20%
      height: 520px
      display: inline-block
      vertical-align: top
      margin-left: 2%
      overflow-y: auto
      .images
        width: 100%
    .left-img
      width: 78%
      height: 520px
      display: inline-block
      .images
        width: 100%
        height: 100%
    .class
      display: block
    .show
      display: none

</style>
