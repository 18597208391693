import { render, staticRenderFns } from "./alarmDisposeDetails.vue?vue&type=template&id=0605f75b&scoped=true"
import script from "./alarmDisposeDetails.vue?vue&type=script&lang=js"
export * from "./alarmDisposeDetails.vue?vue&type=script&lang=js"
import style0 from "./alarmDisposeDetails.vue?vue&type=style&index=0&id=0605f75b&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0605f75b",
  null
  
)

export default component.exports